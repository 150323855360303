import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {ReactComponent as Pulse} from './assets/pulse.svg';

import "./App.css";


const JobPage = ({ match }) => {
  const {
    params: { jobmasterId },
  } = match;
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState();
  const [bin1, setBin1] = useState(false);
  const [bin2, setBin2] = useState(false);
  const [bin3, setBin3] = useState(false);
  const [bin4, setBin4] = useState(false); 

  useEffect(() => {
    fetch(`https://58gqpzi8pc.execute-api.us-east-1.amazonaws.com/prod/?jobId=${jobmasterId}`, {})
      .then((res) => res.json())
      .then((response) => {
        setData(response)
        setIsLoading(false)
        //console.log(`https://58gqpzi8pc.execute-api.us-east-1.amazonaws.com/prod/?jobId=${jobmasterId}`);
        //console.log(response)
      })
      .catch((error) => console.error(error));
  }, [jobmasterId]);

  useEffect(() => {
    if(data){
        //console.log("JOB NOTES",data.job_notes)
        if(data.job_notes){
            if(data.job_notes.bin1) setBin1(data.job_notes.bin1)
            if(data.job_notes.bin2) setBin2(data.job_notes.bin2)
            if(data.job_notes.bin3) setBin3(data.job_notes.bin3)
            if(data.job_notes.bin4) setBin4(data.job_notes.bin4)   
            //console.log("JOB NOTES BIN STATUS >> 1: "+bin1+"  2: "+bin2+"  3: "+bin3+"  4: "+bin4)
        }
    }
 }, [data]);


    const handleSubmit = (evt) => {
        //console.log(evt);
        evt.preventDefault()
        //alert(`Submitting Form`)
        //console.log("BIN STATUS >> 1: "+bin1+"  2: "+bin2+"  3: "+bin3+"  4: "+bin4)
        setIsSubmitting(true);

        var bodyContent =  JSON.stringify({ 'jobId': data.jobs_master_id,
                              'jobNotes': {
                                    'bin1': bin1,
                                    'bin2': bin2,
                                    'bin3': bin3,
                                    'bin4': bin4,
                                }
                             })

        // console.log("TO TRANSMIT:", bodyContent)

        const requestOptions = {
            method: 'POST',
            crossDomain:true,
            body: bodyContent
        };

        fetch('https://58gqpzi8pc.execute-api.us-east-1.amazonaws.com/prod', requestOptions)
        .then((res) => res.json())
        .then(response => {
            //console.log("RES:",response);
        })
        .catch((error) => console.error(error));


    }

    const handleBin = (evt) => {
        //console.log("BIN: "+evt.target.id+" VALUE: "+evt.target.checked)

        // UPDATE BIN
        switch(evt.target.id){
            case "bin1":
                setBin1(evt.target.checked)
                break
            case "bin2":
                setBin2(evt.target.checked)
                break
            case "bin3":
                setBin3(evt.target.checked);
                break
            case "bin4":
                setBin4(evt.target.checked);
                break;     
            default:
                console.log("BIN NOT FOUND TO UPDATE")
                break;  
        }
    }
  

  return (
    <div className="AppInternal">
      { isSubmitting && (
            <div className="final">
              <h1>Your report has been submitted</h1>
            </div>
      )}


      { isLoading && (
            <div className="isLoading">
                <Pulse />
            </div>
      )}


      {!isLoading && data.load_details_current && !isSubmitting && (
        <div>
            <div className="AssetName">
                <span className="nameID">Asset</span> <span>{data.asset_name}</span>
            </div>
            <div className="AssetReturned">
                <span className="returnedID">Returned</span> <span>{data.job_end}</span>
            </div>

            <div className="Instructions">
                Adjust the actual status column to report what was visually inspected when the asset was returned. When finished, click submit report. **Actual Status will load any previously saved reported values. 
            </div>

            <div className="reports">
                <div className="leftColumn">
                    <strong>Reported Status</strong>
                    <div className="footnote"><i>from device (unalterable)</i></div>
                    <div>

                            <div className="status">BIN 1
                                <label className="switch">
                                <input disabled="disabled" type="checkbox" defaultChecked={data.load_details_current.bin1} value={data.load_details_current.bin1} />
                                <span className="slider round"></span>
                                </label>
                            </div>

                            <div className="status">BIN 2
                                <label className="switch">
                                <input disabled="disabled" type="checkbox" defaultChecked={data.load_details_current.bin2} value={data.load_details_current.bin2} />
                                <span className="slider round"></span>
                                </label>
                            </div>

                            <div className="status">BIN 3
                                <label className="switch">
                                <input disabled="disabled" type="checkbox" defaultChecked={data.load_details_current.bin3} value={data.load_details_current.bin3} />
                                <span className="slider round"></span>
                                </label>
                            </div>

                            <div className="status">BIN 4
                                <label className="switch">
                                <input disabled="disabled"  type="checkbox" defaultChecked={data.load_details_current.bin4} value={data.load_details_current.bin4} />
                                <span className="slider round"></span>
                                </label>
                            </div>

                    </div>
                </div>
                <div className="rightColumn">
                    <strong>Actual Status</strong>
                    <div className="footnote"><i>visually inspected</i></div>
                    <div>
                        { data.job_notes ? (

                        <div>
                            <form>

                            <input type="hidden" id="jobId" name="jobId" value={data.jobs_master_id} />

                                <div className="status">
                                    <label className="switch">
                                    <input onChange={handleBin} id="bin1" name="bin1" type="checkbox" defaultChecked={data.job_notes.bin1} value={data.job_notes.bin1} />
                                    <span className="slider round"></span>
                                    </label>
                                </div>

                                <div className="status">
                                    <label className="switch">
                                    <input onChange={handleBin} id="bin2" name="bin2" type="checkbox" defaultChecked={data.job_notes.bin2} value={data.job_notes.bin2} />
                                    <span className="slider round"></span>
                                    </label>
                                </div>

                                <div className="status">
                                    <label className="switch">
                                    <input onChange={handleBin} id="bin3" name="bin3" type="checkbox" defaultChecked={data.job_notes.bin3} value={data.job_notes.bin3} />
                                    <span className="slider round"></span>
                                    </label>
                                </div>

                                <div className="status">
                                    <label className="switch">
                                    <input onChange={handleBin} id="bin4" name="bin4"  type="checkbox" defaultChecked={data.job_notes.bin4} value={data.job_notes.bin4} />
                                    <span className="slider round"></span>
                                    </label>
                                </div>

                            </form>
                        </div>

                        ):
                        (
                       <div>
                        <div className="status">
                            <label className="switch">
                            <input onChange={handleBin} id="bin1" name="bin1"  type="checkbox" value="false" />
                            <span className="slider round"></span>
                            </label>
                        </div>

                        <div className="status">
                            <label className="switch">
                            <input  onChange={handleBin} id="bin2" name="bin2"  type="checkbox" value="false" />
                            <span className="slider round"></span>
                            </label>
                        </div>

                        <div className="status">
                            <label className="switch">
                            <input onChange={handleBin} id="bin3" name="bin3" type="checkbox" value="false"/>
                            <span className="slider round"></span>
                            </label>
                        </div>

                        <div className="status">
                            <label className="switch">
                            <input onChange={handleBin} id="bin4" name="bin4"  type="checkbox" value="false" />
                            <span className="slider round"></span>
                            </label>
                        </div>
                        </div>
                        )
                        }

                    </div>
                </div>
            </div>
            <div className="submitReport">
               <a type="submit" className="btn btn--svg js-animated-button" href="#" onClick={handleSubmit}>
                   <span className="btn--svg__label">Submit Report</span>
                    <svg className="btn--svg__circle" width="100%" x="0px" y="0px" viewBox="0 0 60 60" enableBackground="new 0 0 60 60">
                        <circle className="js-discover-circle" fill="#FFF" cx="30" cy="30" r="28.7"></circle>
                    </svg>
                    <svg className="btn--svg__border" x="0px" y="0px" preserveAspectRatio="none" viewBox="2 29.3 56.9 13.4" enableBackground="new 2 29.3 56.9 13.4" width="190">
                        <g className="btn--svg__border--left js-discover-left-border" id="Calque_2">
                        <path fill="none" stroke="#FFF" strokeWidth="0.5" strokeMiterlimit="1" d="M30.4,41.9H9c0,0-6.2-0.3-6.2-5.9S9,30.1,9,30.1h21.4"></path>
                        </g>
                        <g className="btn--svg__border--right js-discover-right-border" id="Calque_3">
                        <path fill="none" stroke="#FFF" strokeWidth="0.5" strokeMiterlimit="1" d="M30.4,41.9h21.5c0,0,6.1-0.4,6.1-5.9s-6-5.9-6-5.9H30.4"></path>
                        </g>
                    </svg>
                </a>
            </div>       
        </div>
      )}
    

    </div>
  );
};

const HomePage = () => {
  return (
    <div className="App">
      <h1>a.ntfy.link</h1>
    </div>
  );
};

const App = () => {
  return (
    <>
      <Router>
        <Route exact path="/" component={HomePage} />
        <Route path="/:jobmasterId" component={JobPage} />
      </Router>
    </>
  );
};

export default App;